import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faResearchgate } from '@fortawesome/free-brands-svg-icons/faResearchgate';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://www.linkedin.com/pub/annina-b-schmid/22/6b9/510',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'https://twitter.com/anninabschmid',
    label: 'Twitter',
    icon: faTwitter,
  },
  {
    link: 'https://www.researchgate.net/profile/Annina-Schmid',
    label: 'Research Gate',
    icon: faResearchgate,
  },
];

export default data;
