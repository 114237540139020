import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import Main from './layouts/Main'; // fallback for lazy pages
import './static/css/main.scss'; // All of our styles

ReactGA.initialize('G-FGPKS4YTXK');

const { PUBLIC_URL } = process.env;

// Every route - we lazy load so that each page can be chunked
// NOTE that some of these chunks are very small. We should optimize
// which pages are lazy loaded in the future.
const About = lazy(() => import('./pages/About'));
const Lab = lazy(() => import('./pages/Lab'));
const Index = lazy(() => import('./pages/Index'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Publications = lazy(() => import('./pages/Publications'));
const Research = lazy(() => import('./pages/Research'));
const Resources = lazy(() => import('./pages/Resources'));
const Copyright = lazy(() => import('./pages/Copyright'));

const App = () => (
  <BrowserRouter basename={PUBLIC_URL}>
    <Suspense fallback={<Main />}>
      <Switch>
        <Route exact path="/" component={Index} />
        <Route path="/about" component={About} />
        <Route path="/lab" component={Lab} />
        <Route path="/publications" component={Publications} />
        <Route path="/research" component={Research} />
        <Route path="/resources" component={Resources} />
        <Route path="/copyright" component={Copyright} />
        <Route component={NotFound} status={404} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default App;
