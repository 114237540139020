import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

const SideBar = () => (
  <section id="sidebar">

    <section className="blurb">
      <h1>Contact</h1>
      <p><a href="http://www.ndcn.ox.ac.uk/about">Nuffield Department of Clinical Neurosciences</a><br /><a href="https://www.neuroscience.ox.ac.uk/research-directory/annina-schmid">Oxford Neuroscience</a></p>
      <ul className="contact">
        <li className="telephone"><a href="tel:441865223254">+44 1865 223254</a></li>
        <li className="email"><a href="mailto:annina.schmid@neuro-research.ch">annina.schmid@neuro-research.ch</a></li>
      </ul>
      <ul className="actions">
        <li>
          {window.location.pathname.includes('/publications') ? <Link to="/about" className="button">About Me</Link> : iff(window.location.pathname.includes('/about'), <Link to="/research" className="button">Research</Link>, <Link to="/publications" className="button">Publications</Link>)}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; <Link to="/copyright">Copyright</Link>.</p>
    </section>
  </section>
);

export default SideBar;
