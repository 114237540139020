import React from 'react';
import { Link } from 'react-router-dom';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const Intro = () => (
  <section id="intro">
    <Link to="/" className="logo">
      <img src={`${PUBLIC_URL}/images/annina-schmid.png`} alt="Nina Schmid" />
    </Link>
    <header>
      <h2><Link to="/">Professor Annina B Schmid</Link></h2>
      <p>Wellcome Clinical Career Development Fellow<br /><span itemProp="jobTitle">Professor</span> of Pain Neurosciences at the University of Oxford</p>
      <p>MMACP, MManipTher, PhD</p>
    </header>
  </section>
);

export default Intro;
