const routes = [
  {
    index: true,
    label: 'Neuro Research',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Lab',
    path: '/lab',
  },
  {
    label: 'Research',
    path: '/research',
  },
  {
    label: 'Publications',
    path: '/publications',
  },
  {
    label: 'Resources',
    path: '/resources',
  },
];

export default routes;
